import { Box, Chip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import DoneIcon from '@mui/icons-material/Done';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

const PREFIX = 'WizardStep';

const classes = {
  box: `${PREFIX}Box`,
  typography: `${PREFIX}Typography`,
};

const StyledBox = styled(Box)({
  [`&.${classes.box}`]: {
    '&:not(:last-child)': {
      marginBottom: '2rem',
    },
  },
  [`& .${classes.typography}`]: {
    margin: '0.625rem 0 0.1875rem 0',
  },
});

interface Props {
  readonly step: number;
  readonly activeStep: number;
  readonly title: string;
  readonly description: string;
}

export function WizardStep(props: Props) {
  const { activeStep, description, step, title } = props;

  const active = activeStep === step;
  const completed = activeStep > step;

  let chipIcon = <i />;
  let currentClass = 'is-inactive';
  if (active === true) {
    currentClass = 'is-active';
    chipIcon = <NavigateNextIcon />;
  } else if (completed === true) {
    currentClass = 'is-completed';
    chipIcon = <DoneIcon />;
  }

  return (
    <StyledBox className={classes.box}>
      <Chip
        className={currentClass}
        label={`Step ${step.toString()}`}
        deleteIcon={chipIcon}
        onDelete={() => {}}
      />
      <Typography variant="h3" className={classes.typography}>
        {title}
      </Typography>
      <Typography>{description}</Typography>
    </StyledBox>
  );
}
